<template>
  <v-card
    :loading="loading"
    class="moholand-shadow mx-auto rounded-xl overflow-hidden"
    max-width="300"
  >
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-card-text class="pa-0 text-center pt-4">
      <v-img
        height="200"
        :src="img"
        @click="navigateTo(url)"
        contain
        style="cursor:pointer;"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              v-if="img"
              indeterminate
              color="grey lighten-2"
            ></v-progress-circular>
            <v-btn v-else text disabled>
              بدون تصویر
            </v-btn>
          </v-row>
        </template>
      </v-img>
      <h3 @click="navigateTo(url)" class="product-title">{{ name }}</h3>
      <v-row no-gutters class="pa-2 text-center">
        <v-col cols="12" v-if="!isValidDiscount">
          <v-chip color="white" />
        </v-col>
        <v-col
          cols="12"
          class="green--text my-2 text-medium"
          v-if="price == '0'"
        >
          رایگان
        </v-col>
        <template v-else-if="isValidDiscount">
          <v-col cols="12" class="align-center">
            <div class="d-flex flex-wrap justify-center align-center">
              <del class="font-weight-bold">
                {{ new Intl.NumberFormat().format(oldPrice) }}
                تومان
              </del>
              <v-chip
                class="mr-2 rounded-xl"
                text-color="white"
                label
                color="red"
              >
                %
                {{
                  new Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 0,
                  }).format(100 - (100 * price) / oldPrice)
                }}
              </v-chip>
            </div>
          </v-col>
          <v-col cols="12" class="green--text my-2 text-medium">
            {{ new Intl.NumberFormat().format(price) }} تومان
          </v-col>
        </template>
        <v-col cols="12" v-else-if="oldPrice" class="green--text my-2 text-medium">
          {{ new Intl.NumberFormat().format(oldPrice) }} تومان
        </v-col>
        <v-col cols="12" v-else class="green--text my-2 text-medium">
          {{ new Intl.NumberFormat().format(price) }} تومان
        </v-col>
        <vue-countdown
          v-if="remainTime > 0"
          :time="remainTime"
          :interval="1000"
          v-slot="{ days, hours, minutes, seconds }"
          class="mx-auto"
        >
          <div class="d-flex justify-center align-center ">
            <div>
              {{
                (days > 0 ? days + ":" : "") +
                  hours +
                  ":" +
                  minutes +
                  ":" +
                  seconds
              }}
            </div>
            <v-icon class="mr-1">
              mdi-clock-outline
            </v-icon>
          </div>
        </vue-countdown>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { getUnixDifferenceTimeFromNow } from "../../constants/TimeFunctions";
export default {
  components: {},
  directives: {},
  mounted() {
    console.log(this.img);
  },
  props: {
    product: {
      type: Object,
    },
    name: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    img: {
      type: String,
      default: null,
    },
    price: {
      type: Number | String,
      required: true,
    },
    oldPrice: {
      type: Number | String,
    },
    endDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      now: new Date(),
    };
  },
  computed: {
    isValidDiscount() {
      const diff = getUnixDifferenceTimeFromNow(this.product.end_date_offer);
      return diff && diff < 0;
    },
    remainTime: {
      get() {
        if (this.endDate) {
          return new Date(this.endDate) - this.now;
        } else return 0;
      },
    },
  },
  methods: {
    navigateTo: function(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style scoped>
.product-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
}
</style>
