import Vue from "vue";
import VueRouter from "vue-router";
import Pages from "./Pages";

Vue.use(VueRouter);

// const routes = Pages.map((page) => ({
// 	path: page.path,
// 	name: page.name,
// 	component: page.component,
// }));

const routes = Pages;

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return {
      x: 0,
      y: 0,
      behavior: "smooth",
    };
  },
});

export default router;
