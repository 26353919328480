var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.userData)?_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[(_vm.$store.state.userNotifications.length)?_c('v-badge',{attrs:{"color":"error","content":_vm.$store.state.userNotifications.length,"left":"","overlap":""}},[_c('v-icon',[_vm._v("mdi-email-outline")])],1):_c('v-icon',[_vm._v("mdi-email-outline")])],1)]}}],null,false,3171685193)},[(_vm.$store.state.userNotifications.length)?_c('v-list',{attrs:{"max-width":"400px","max-height":"70vh"}},[_c('div',{staticClass:"d-flex align-center"},[_c('small',[_vm._v("نوتیفیکیشن ها")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","fab":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.setNotificationAsRead()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-checkbox-marked-outline")])],1)]}}],null,false,1000151270)},[_c('small',[_vm._v("علامت به عنوان خوانده شده")])])],1),_vm._l((_vm.$store.state.userNotifications.filter(
        function (v) { return !!v.data; }
      )),function(notif,i){return _c('div',{key:'notif' + i,staticClass:"px-2 white",class:{ 'grey lighten-3 grey--text': notif.read_at }},[_c('div',{staticClass:"d-flex"},[_c('small',[_vm._v(_vm._s(notif.data.message))])]),_c('v-divider')],1)})],2):_c('v-list',{staticClass:"px-2",attrs:{"width":"300px"}},[_c('div',[_vm._v("پیامی ندارید.")])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }