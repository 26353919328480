<template>
  <div class="d-flex grey lighten-2 pa-3 rounded-lg mt-2">
    <div v-if="img" class="cart-img">
      <v-img :src="img" class="rounded-lg elevation-2">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-1"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </div>
    <div class="my-auto mr-4">
      <h4 class="black--text product-title">
        {{ title }}
      </h4>
      <div class="green--text">
        <b style="font-size: 20px" v-if="price == '0'">
          رایگان
        </b>
        <b style="font-size: 20px" v-else>
          {{ new Intl.NumberFormat().format(price) }} تومان
        </b>
      </div>
    </div>
    <v-spacer />
    <v-btn
      color="error"
      fab
      small
      elevation="0"
      class="rounded-lg mr-4 my-auto"
      @click="onDeleteClick"
      v-if="withRemove"
      :loading="loading"
    >
      <v-icon size="40">
        mdi-close
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
    };
  },
  props: {
    img: {
      type: Object | String,
    },
    title: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
    withRemove: {
      type: Boolean,
      default: true,
    },
    productId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    onDeleteClick() {
      // this.$store.commit("removeFromCart", this.productId);
      this.loading = true;
      this.$root.$emit("removeFromCart", this.productId, () => {
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>
.cart-items {
  max-height: 300px;
  overflow-y: auto;
}
.cart-img {
  width: 80px !important;
  height: 80px !important;
}
.product-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
