<template>
  <v-app>
    <v-main>
      <my-global-snackbar />
      <global-loading />
      <Header v-show="innerPages" />
      <router-view target="_blank" />
      <cart-button v-show="innerPages && showCart" />
      <Footer v-show="innerPages" />
    </v-main>
  </v-app>
</template>

<script>
import GlobalLoading from "./components/GlobalLoading.vue";
import MyGlobalSnackbar from "./components/MyGlobalSnackbar.vue";
import CartButton from "./components/Pages/CartButton.vue";
import Footer from "./components/Pages/Footer.vue";
import Header from "./components/Pages/Header.vue";
export default {
  components: { MyGlobalSnackbar, Header, Footer, CartButton, GlobalLoading },
  name: "App",
  data: () => ({
    innerPages: true,
    showCart: true,
  }),
  watch: {
    $route: {
      immediate: true,
      handler(newRoute) {
        if (newRoute.matched.some((val) => val.path?.includes("/panel")))
          this.innerPages = false;
        else if (newRoute.matched.some((val) => val.path?.includes("/404")))
          this.innerPages = false;
        else if (newRoute.matched.some((val) => val.path?.includes("/login")))
          this.innerPages = false;
        else if (
          newRoute.matched.some((val) => val.path?.includes("/register"))
        )
          this.innerPages = false;
        else this.innerPages = true;

        if (newRoute.matched.some((val) => val.path?.includes("/checkout")))
          this.showCart = false;
        else if (newRoute.matched.some((val) => val.path?.includes("/login")))
          this.showCart = false;
        else if (
          newRoute.matched.some((val) => val.path?.includes("/register"))
        )
          this.showCart = false;
        else this.showCart = true;
      },
    },
  },
};
</script>
