<template>
  <v-snackbar :color="data.type" v-model="show" centered top timeout="4000">
    {{ data.text }}
    <template v-slot:action="{ attrs }">
      <v-btn fab text x-small v-bind="attrs" @click="close">
        <v-icon dark>
          mdi-close-circle
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      data: {
        text: "",
        type: "error",
      },
    };
  },
  mounted() {
    this.$root.$on("toast", this.toast);
  },
  methods: {
    close() {
      this.show = false;
    },
    toast({ text, type = "error" }) {
      this.data = {
        text,
        type,
      };
      this.show = true;
    },
  },
};
</script>

<style></style>
