<template>
  <span>
    <main-page-drawer ref="mainDrawer" :items="items" />
    <search-modal />
    <v-row v-if="$store.state.settings.top_banner" no-gutters>
      <v-col cols="12" style="background: #4802d3;height: 100px">
        <v-img
          max-height="80px"
          :src="
            $store.state.settings.top_banner.url ||
              require('../../assets/img/top-home-banner.jpg')
          "
          @click="navigateTo"
        >
        </v-img>
      </v-col>
    </v-row>
    <v-app-bar fixed style="position: sticky; z-index: 100" elevation="0">
      <v-container class="hidden-sm-and-down">
        <v-toolbar flat color="transparent" dense>
          <router-link to="/">
            <v-img
              contain
              class="white--text"
              max-height="50px"
              max-width="200px"
              :src="
                $store.state.settings.logo
                  ? $store.state.settings.logo.url
                  : require('../../assets/img/logo-persian.png')
              "
            >
            </v-img>
          </router-link>
          <v-toolbar-items class="hidden-sm-and-down">
            <v-tabs
              background-color="transparent"
              show-arrows
              style="max-width: 40vw"
            >
              <v-tabs-slider color="transparent"></v-tabs-slider>
              <v-tab
                class="pa-0 ma-0"
                v-for="item in items"
                :key="item.id"
                :href="'#tab-' + item.id"
              >
                <v-btn text :to="'/' + item.url" v-if="!item.child.length">
                  {{ item.title }}
                </v-btn>
                <v-menu
                  v-if="item.child.length"
                  :close-on-content-click="false"
                  open-on-hover
                  bottom
                  offset-y
                  max-height="80vh"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn text :to="'/' + item.url" v-on="on">
                      {{ item.title }}
                    </v-btn>
                  </template>
                  <v-list max-width="1000px" style="overflow: hidden">
                    <v-row
                      class="px-8 py-4"
                      v-if="item.child.some((v) => v.child.length)"
                    >
                      <v-col
                        class="mb-0 py-0"
                        md="2"
                        sm="12"
                        v-for="children in item.child"
                        :key="children.id"
                        v-model="children.active"
                      >
                        <v-list flat class="pt-0">
                          <v-subheader class="pa-0">
                            <a
                              @click="
                                () =>
                                  children.url &&
                                  $router.replace('/' + children.url)
                              "
                              style="font-weight:bold;color: #ff6d00"
                            >
                              {{ children.title }}
                            </a>
                          </v-subheader>
                          <v-row no-gutters>
                            <v-col
                              cols="12"
                              v-for="sub in children.child"
                              :key="sub.id"
                              class="my-1"
                            >
                              <a
                                @click="
                                  () =>
                                    sub.url && $router.replace('/' + sub.url)
                                "
                                class="grey--text text--darken-2"
                              >
                                {{ sub.title }}
                              </a>
                            </v-col>
                          </v-row>
                        </v-list>
                      </v-col>
                    </v-row>
                    <template v-else>
                      <v-list-item
                        v-for="children in item.child"
                        :key="children.id"
                        v-model="children.active"
                        :to="'/' + children.url"
                      >
                        {{ children.title }}
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
              </v-tab>
            </v-tabs>
          </v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon @click="onSearchClicked">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
          <notifications />
          <v-card style="background: #FF6D00" class="pa-2 mx-auto" rounded>
            <v-row align="end" class="fill-height">
              <v-col align-self="middle" class="pa-0" cols="12">
                <v-menu
                  open-on-hover
                  bottom
                  offset-y
                  v-if="$store.state.userData"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex justify-center align-center">
                      <v-list-item
                        color="rgba(0, 0, 0, .4)"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <span
                              v-if="
                                $store.state.userData.last_name ||
                                  $store.state.userData.first_name
                              "
                            >
                              {{
                                $store.state.userData.first_name +
                                  " " +
                                  $store.state.userData.last_name
                              }}
                            </span>
                            <span v-else>
                              نامشخص
                            </span>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <small>اعتبار شما:</small>
                            <small v-if="$store.state.userData">
                              {{
                                Intl.NumberFormat().format(
                                  $store.state.userData.balance
                                )
                              }}
                              تومان
                            </small>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-avatar
                        class="profile ml-2"
                        color="grey"
                        size="42"
                        rounded
                      >
                        <v-img
                          :src="
                            $store.state.userData.avatar_url ||
                              require('@/assets/img/man.jpg')
                          "
                        ></v-img>
                      </v-avatar>
                    </div>
                  </template>

                  <v-list class="mb-0 pb-0">
                    <!-- <v-list-item
                      link
                      style="max-width:unset"
                      :to="'/user/files'"
                    >
                      <v-list-item-title>فایل های من</v-list-item-title>
                    </v-list-item> -->
                    <v-list-item
                      link
                      style="max-width:unset"
                      :to="'/dashboard'"
                    >
                      <v-list-item-title>پنل کاربری</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      link
                      style="max-width:unset"
                      :to="'/sellers/' + $store.state.userData.id"
                      v-if="
                        $store.state.userData &&
                          $store.state.userData.roleName == 'vendor'
                      "
                    >
                      <v-list-item-title>فروشگاه من</v-list-item-title>
                    </v-list-item>
                    <!--                    <v-list-item link style="max-width:unset">-->
                    <!--                      <v-list-item-title>مورد علاقه ها</v-list-item-title>-->
                    <!--                    </v-list-item>-->
                    <v-list-item
                      link
                      style="max-width:unset;background: #2684fe"
                      dark
                      @click="exitAccount"
                    >
                      <v-list-item-title>خروج</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn
                  v-if="$store.state.userData == null"
                  text
                  dark
                  to="/login"
                >
                  همین حالا وارد شوید
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-toolbar>
      </v-container>
      <v-container class="pa-0 hidden-md-and-up">
        <v-toolbar flat color="transparent">
          <v-btn icon @click="$refs.mainDrawer.toggle()">
            <v-icon>
              mdi-menu
            </v-icon>
          </v-btn>
          <v-spacer />
          <router-link to="/">
            <v-img
              contain
              class="white--text"
              max-height="50px"
              max-width="150px"
              :src="
                $store.state.settings.logo
                  ? $store.state.settings.logo.url
                  : require('../../assets/img/logo-persian.png')
              "
            >
            </v-img>
          </router-link>
          <v-spacer />
          <v-btn icon @click="onSearchClicked">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
          <v-menu open-on-hover bottom offset-y v-if="$store.state.userData">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-badge
                  v-if="$store.state.userNotifications.length"
                  color="error"
                  :content="$store.state.userNotifications.length"
                  left
                  overlap
                >
                  <v-icon>mdi-email-outline</v-icon>
                </v-badge>
                <v-icon v-else>mdi-email-outline</v-icon>
              </v-btn>
            </template>

            <v-list
              width="300px"
              max-height="70vh"
              v-if="$store.state.userNotifications.length"
            >
              <div
                v-for="(notif, i) in $store.state.userNotifications.filter(
                  (v) => !!v.data
                )"
                :key="'notif' + i"
                class="px-2 white"
              >
                <small>{{ notif.data.message }}</small>
                <v-divider />
              </div>
            </v-list>
            <v-list width="300px" v-else class="px-2">
              <div>پیامی ندارید.</div>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-container>
    </v-app-bar>
  </span>
</template>

<script>
import MyAxios from "@/constants/MyAxios";
import SearchModal from "./SearchModal.vue";
import MainPageDrawer from "./MainPageDrawer.vue";
import Notifications from "./Notifications.vue";

export default {
  name: "Header",
  components: {
    SearchModal,
    MainPageDrawer,
    Notifications,
    // Drawer
  },
  props: {
    title: {
      type: String,
    },
    subtitle: String,
    height: {
      type: Number,
      default: 400,
    },
    drawerRef: Object,
  },
  created() {
    this.getItems();
  },
  methods: {
    getItems() {
      if (!this.loading) this.loading = true;

      MyAxios.get("/pages/menu")
        .then((response) => {
          this.loading = false;
          this.items = response.data.menu;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    navigateTo() {
      if (this.$store.state.settings.banner_url)
        window.open(this.$store.state.settings.banner_url, "_blank");
    },
    onSearchClicked() {
      this.$root.$emit("openSearchDialog");
    },
    getUserData() {
      console.log("this.$store.state.userData", this.$store.state.userData);
    },
    exitAccount() {
      this.$store.commit("exit");

      this.$router.replace("/");
    },
  },
  data: () => ({
    loading: true,
    selectedItem: 1,
    items: [],
    menuitems: [
      { text: "Real-Time", icon: "mdi-clock" },
      { text: "Audience", icon: "mdi-account" },
      { text: "Conversions", icon: "mdi-flag" },
    ],
  }),
};
</script>

<style scoped>
.theme--light.v-card {
  background: unset;
}
.logo {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}
.v-sheet.v-card {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
button.v-btn {
  letter-spacing: 0;
}
.v-btn:before {
  background: unset;
}
.v-btn:hover {
  color: #ddd;
}
.v-list-item {
  max-width: 175px;
}
</style>
