<template>
  <v-card
    :loading="loading"
    class="moholand-shadow mx-auto rounded-xl ma-0"
  >
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-card-text class="pa-0 text-center pt-4">
      <h3 class="orange--text">پیشنهادات لحظه ای</h3>
      <router-link :to="url">
        <v-img height="300" style="cursor:pointer;" contain :src="img">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                v-if="img"
                indeterminate
                color="grey lighten-2"
              ></v-progress-circular>
              <v-btn v-else text disabled>
                بدون تصویر
              </v-btn>
            </v-row>
          </template>
        </v-img>
        <h3 class="black--text mt-2" style="cursor:pointer;">{{ name }}</h3>
      </router-link>
      <v-row class="pa-2 text-center mt-1">
        <v-col v-if="price == '0'" class="green--text text-medium">
          رایگان
        </v-col>
        <template v-else-if="isValidDiscount">
          <v-col class="green--text text-medium">
            {{ new Intl.NumberFormat().format(price) }} تومان
          </v-col>
          <v-spacer />
          <v-col v-if="oldPrice">
            <del>
              {{ new Intl.NumberFormat().format(oldPrice) }}
              تومان
            </del>
          </v-col>
        </template>
        <v-col v-else-if="oldPrice" class="green--text text-medium text-center">
          {{ new Intl.NumberFormat().format(oldPrice) }} تومان
        </v-col>
        <v-col v-else class="green--text text-medium text-center">
          {{ new Intl.NumberFormat().format(price) }} تومان
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { getUnixDifferenceTimeFromNow } from "@/constants/TimeFunctions";
export default {
  components: {},
  directives: {},
  props: {
    product: {
      type: Object,
    },
    name: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    img: {
      type: Object | String,
      required: true,
    },
    price: {
      type: Number | String,
      required: true,
    },
    oldPrice: {
      type: Number | String,
    },
  },
  data() {
    return {
      now: new Date(),
    };
  },
  computed: {
    isValidDiscount() {
      const diff = getUnixDifferenceTimeFromNow(this.product?.end_date_offer);
      return diff && diff < 0;
    },
  },
  methods: {
    navigateTo: function(url) {
      this.$router.replace(url);
    },
  },
};
</script>

<style scoped>
.progressbar {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
