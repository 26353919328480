<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    fullscreen
    hide-overlay
    @keydown.esc="onClose"
  >
    <v-row class="main-div ma-auto" align="center" justify="center">
      <v-col cols="12" md="6" class="white--text">
        <h1>جستجو</h1>
        <v-form @submit.prevent="onSubmit">
          <div class="d-flex">
            <div class="my-auto">
              جستجو با زدن Enter و بستن با زدن Esc
            </div>
            <v-spacer />
            <v-btn icon class="my-auto" @click="onClose">
              <v-icon color="white" size="30">
                mdi-close-circle-outline
              </v-icon>
            </v-btn>
          </div>
          <div class="d-flex rounded-lg white mt-4 align-center">
            <v-text-field
              v-model="search"
              flat
              solo
              label="دنبال چی میگردی؟"
              hide-details
              class="rounded-r-lg"
            />
            <v-btn
              elevation="0"
              fab
              class="rounded-l-lg rounded-0"
              color="orange darken-1"
              dark
              type="submit"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div>
        </v-form>
      </v-col>
    </v-row>
  </v-dialog>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import VFormBase from "vuetify-form-base";
import MySnackbar from "@/components/MySnackbar";

export default {
  components: { VFormBase, MySnackbar },
  data() {
    return {
      modal: false,
      loading: false,
      search: "",
      desc: {
        value: "",
        show: false,
      },
      rules: Constants.rules,
    };
  },
  props: {
    // value: {
    // 	// v-model instance
    // 	type: String,
    // 	required: true,
    // },
    withModel: {
      type: Boolean,
      default: true,
    },
    withButton: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.$root.$on("openSearchDialog", () => this.onChangeBtnClick());
  },
  methods: {
    onOkClick() {
      if (this.$refs.form.validate()) {
        // if (this.withModel) this.$emit("input", this.newpassword.value);
        // else this.PostNewPassword();
        this.postReport();
      }
    },
    onChangeBtnClick() {
      this.modal = true;
    },
    onClose() {
      this.modal = false;
    },
    onSubmit() {
      this.$router.push({
        name: "Products",
        query:
          this.search.length > 0
            ? {
                name: this.search,
              }
            : undefined,
      });
      this.onClose();
    },
  },
};
</script>

<style scoped>
.ico-shadow {
  box-shadow: 0 0 0px 7px #cbcbcb, 0 0 4px 13px #dcdcdc;
}
.main-div {
  width: 100vw;
  height: 100vh;
  background-color: deepskyblue;
  z-index: 11;
}
</style>
