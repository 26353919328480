<template>
  <v-card
    class="moholand-shadow mx-auto rounded-xl overflow-hidden"
    max-width="300"
  >
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <router-link :to="url">
      <v-img
        :height="dynamicHeight ? undefined : 300"
        :src="img"
        style="cursor: pointer"
        :contain="dynamicHeight ? undefined : true"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              v-if="img"
              indeterminate
              color="grey lighten-2"
            ></v-progress-circular>
            <v-btn v-else text disabled>
              بدون تصویر
            </v-btn>
          </v-row>
        </template>
        <div class="d-flex">
          <v-chip
            class="ma-2 rounded-lg rounded-xl"
            text-color="white"
            label
            v-if="oldPrice && isValidDiscount"
            color="red"
          >
            %
            {{
              new Intl.NumberFormat("en-US", {
                maximumFractionDigits: 0,
              }).format(100 - (100 * price) / oldPrice)
            }}
          </v-chip>
          <v-spacer />
          <v-chip
            class="ma-2 rounded-lg rounded-xl"
            text-color="white"
            label
            color="info"
          >
            <v-icon class="ml-1">mdi-download</v-icon>
            {{ product.download_count || 0 }}
          </v-chip>
        </div>
      </v-img>
    </router-link>

    <router-link :to="url">
      <v-card-title class="product-title pb-0 black--text">
        {{ name }}
      </v-card-title>
    </router-link>

    <v-card-text class="d-flex px-0 mt-1" v-if="price == '0'">
      <div class="green--text text-medium mr-4">
        رایگان
      </div>
    </v-card-text>
    <v-card-text class="d-flex px-0 mt-1" v-else>
      <template v-if="isValidDiscount">
        <div class="green--text text-medium mr-4">
          {{ new Intl.NumberFormat().format(price) }} تومان
        </div>
        <v-spacer />
        <div v-if="oldPrice" class="text-left ml-4">
          <del>
            {{ new Intl.NumberFormat().format(oldPrice) }}
            تومان
          </del>
        </div>
      </template>
      <div v-else-if="oldPrice" class="green--text text-medium mr-4">
        {{ new Intl.NumberFormat().format(oldPrice) }} تومان
      </div>
      <div v-else class="green--text text-medium mr-4">
        {{ new Intl.NumberFormat().format(price) }} تومان
      </div>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-card-actions>
      <template v-if="price == '0'">
        <v-menu offset-y v-if="productMedias.length > 1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="error"
              :loading="loading"
              text
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in productMedias"
              :key="index"
              @click="download(item.url || item)"
            >
              <v-list-item-title>فایل {{ index }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          v-else
          color="error"
          :loading="loading"
          text
          small
          @click="
            productMedias.length &&
              download(productMedias[0].url || productMedias[0])
          "
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </template>
      <v-btn
        v-else
        small
        color="error"
        :loading="loading"
        text
        @click="reserve"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-spacer />
      <v-chip
        v-if="product.software"
        small
        :color="product.software.color"
        dark
        class="rounded ltr"
      >
        {{ "." + product.software.format }}
      </v-chip>
      <v-spacer />
      <v-rating
        v-if="product"
        :value="product.rate"
        color="amber"
        dense
        half-increments
        readonly
        size="18"
      ></v-rating>
    </v-card-actions>
  </v-card>
</template>

<script>
import MyAxios from "../../constants/MyAxios";
import { getUnixDifferenceTimeFromNow } from "@/constants/TimeFunctions";
export default {
  props: {
    product: {
      type: Object,
    },
    name: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    img: {
      type: String,
      default: null,
    },
    price: {
      type: Number | String,
    },
    oldPrice: {
      type: Number | String,
    },
    dynamicHeight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    isValidDiscount() {
      const diff = getUnixDifferenceTimeFromNow(this.product.end_date_offer);
      return diff && diff < 0;
    },
    productMedias() {
      return this.product.links_media || [];
    },
  },
  methods: {
    navigateTo: function(url) {
      this.$router.push({ path: url, target: "_blank" });
    },
    reserve() {
      this.loading = true;
      this.$root.$emit("addToCart", this.product, () => {
        this.loading = false;
      });
    },
    download(link) {
      this.increaseDownloadCount();
      window.open(link, "_blank");
    },
    increaseDownloadCount() {
      MyAxios.post("/downloads/create", {
        user_id: this.$store.state.userData?.id || 1,
        media_id:
          this.productMedias.length && this.productMedias[0].id
            ? this.productMedias[0].id
            : null,
        product_id: this.product.id,
      });
    },
  },
};
</script>

<style scoped>
.product-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
}
</style>
