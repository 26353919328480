import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { groupBy } from "../constants/GlobalConstants";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState({ key: "moholand" })],
  state: {
    settings: {
      copyright: "",
      top_banner: null,
      banner_url: null,
      offers_url: null,
      logo: null,
      login_media: null,
      icons_media: [],
      social: {
        instagram: null,
        youtube: null,
        aparat: null,
        telegram: null,
      },
      namad_one: null,
      namad_two: null,
      copyright: "تمامی حقوق سایت متعلق به موهولند میباشد.",
      sliders: [],
      footer_links: [],
    },
    cart: {
      id: null,
      products: [],
      total_price: 0,
    },
    token: null,
    userData: null,
    userNotifications: [],
    userNotificationsCount: {},
    storage_id: null,
  },
  mutations: {
    setSettings(state, settings) {
      let footer_links = settings.footer_links
        ? groupBy(JSON.parse(settings.footer_links), "order")
        : [];

      let sliders = settings.sliders ? JSON.parse(settings.sliders) : [];
      sliders = sliders
        .map((val, i) => ({
          ...val,
          media: settings.sliders_media[i] || null,
        }))
        .filter((val) => !!val.media)
        .sort((a, b) => a.order - b.order);

      state.settings = {
        copyright: settings.copyright || "",
        top_banner: settings.top_banner || null,
        banner_url: settings.banner_url || null,
        offers_url: settings.offers_url || null,
        sliders_media: settings.sliders_media || [],
        logo: settings.logo || null,
        login_media: settings.login_media || null,
        icons_media: settings.icons_media || [],
        social: settings.social
          ? JSON.parse(settings.social)
          : {
              instagram: null,
              youtube: null,
              aparat: null,
              telegram: null,
            },
        namad_one: settings.namad_one || null,
        namad_two: settings.namad_two || null,
        copyright:
          settings.copyright || "تمامی حقوق سایت متعلق به موهولند میباشد.",
        sliders,
        footer_links,
      };
    },
    setToken(state, newToken) {
      state.token = newToken;
    },
    setUserData(state, userData) {
      state.userData = userData;
    },
    setUserNotification(state, userNotifications) {
      state.userNotifications = userNotifications;
    },
    setStorageId(state, storage_id) {
      state.storage_id = storage_id;
    },
    setLoginData(
      state,
      data = {
        userData: {},
        token: {},
      }
    ) {
      state.userData = data.userData;
      state.token = data.token;
      state.storage_id = null;
    },
    setCart(state, cart) {
      state.cart = cart;
    },
    resetCart(state) {
      state.cart = {
        id: null,
        products: [],
        total_price: 0,
      };
    },
    addToCart(state, item) {
      let newProducts = state.cart?.products;
      newProducts.push(item);
      state.cart.products = newProducts;
    },
    removeFromCart(state, id) {
      let index = state.cart?.products.findIndex((val) => val.id == id);
      if (index >= 0) {
        state.cart.products.splice(index, 1);
      }
    },
    exit(state) {
      localStorage.clear("moholandtoken");
      state.cart = {
        id: null,
        products: [],
        total_price: 0,
      };
      state.token = null;
      state.userData = null;
      state.userNotifications = [];
      state.storage_id = null;
    },
    balanceAndNotificationsCount(state, item) {
      state.userData.balance = item?.balance || 0;
      state.userNotificationsCount = item?.notifications || {};
    },
    notificationsCount(state, item) {
      state.userNotificationsCount = item || {};
    },
  },
  actions: {},
  modules: {},
});
