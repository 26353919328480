<template>
	<v-snackbar
		:color="type"
		v-model="show"
		left
        bottom
		timeout="4000"
	>
		{{ text }}
		<template v-slot:action="{ attrs }">
			<v-btn fab text x-small v-bind="attrs" @click="close">
				<v-icon dark>
					mdi-close-circle
				</v-icon>
			</v-btn>
		</template>
	</v-snackbar>
</template>

<script>
export default {
	props: {
		value: {
			type: Boolean,
			required: true,
		},
		type: {
			type: String,
			default: "success",
		},
		text: {
			type: String,
			required: true,
		},
	},
	computed: {
		show: {
			get() {
				return this.value;
			},
			set(val) {
                this.$emit("input", val);
			},
		},
	},
	methods: {
		close() {
			this.$emit("input", false);
		},
	},
};
</script>

<style></style>
