import Axios from 'axios';
import { Constants, refreshToken } from './GlobalConstants';

const MyAxios = Axios.create({
  baseURL: Constants.mainURL,
  timeout: 180000,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});

MyAxios.interceptors.request.use(
  function(config) {
    const storageToken = localStorage.getItem('moholandtoken');
    if (storageToken && !config.headers.Authorization) {
      let parsedToken = JSON.parse(storageToken);
      config.headers.Authorization = `Bearer ${parsedToken.access_token}`;
    } else if (config.headers.Authorization && !storageToken) {
      config.headers.Authorization = '';
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

MyAxios.interceptors.response.use(
  response => {
    // console.log(response.config.url, response.data.data || response.data);
    return response;
  },
  error => {
    console.log(error.config.url, error.response);
    if (!Axios.isCancel(error)) {
      // if (error.response && error.response.data && error.response.data.error) {
      // 	Toast.show(error.response.data.error, {duration: Toast.durations.LONG});
      // }
      // else if (error.response.status == 400){
      // 	Toast.show("The request contains bad syntax or cannot be fulfilled", {duration: Toast.durations.LONG});
      // }
      ////////////////////////////////////////////////////////////////////////////
      if (error?.response?.status == 401) {
        let storageToken = localStorage.getItem('moholandtoken');
        storageToken = JSON.parse(storageToken);

        return refreshToken(storageToken)
          .then(Token => {
            MyAxios.defaults.headers.common['Authorization'] =
              'Bearer ' + Token.access_token;

            error.config.headers.Authorization = 'Bearer ' + Token.access_token;

            localStorage.removeItem('moholandtoken');
            localStorage.setItem('moholandtoken', JSON.stringify(Token));

            return MyAxios.request(error.config);
          })
          .catch(tokenerror => {
            localStorage.removeItem('moholandtoken');
            localStorage.removeItem('moholand');

            // window.location.href = "https://moholand.com/";
            window.location.reload();
            return Promise.reject(tokenerror);
          });
      }
      ///////////////////////////////////////////////////////////////
      // if (status === 401) {
      // 	// will loop if refreshToken returns 401
      // 	return refreshToken(store)
      // 		.then((_) => {
      // 			error.config.headers["Authorization"] =
      // 				"Bearer " + store.state.auth.token;
      // 			error.config.baseURL = undefined;
      // 			return Axios.request(error.config);
      // 			axios(error.response.config);
      // 		})
      // 		.catch((err) => err);
      // }
      // else if (error.response.status == 402){
      // 	Toast.show("Payment Required...", {duration: Toast.durations.LONG});
      // }
      // else if (error.response.status == 403){
      // 	Toast.show("Forbidden Request...", {duration: Toast.durations.LONG});
      // }
      // else if (error.response.status == 404){
      // 	Toast.show("Not Found", {duration: Toast.durations.LONG});
      // }
      // else if (error.response.status >= 500){
      // 	Toast.show("Internal Server Error...", {duration: Toast.durations.LONG});
      // }
      // else Toast.show(JSON.stringify(error.response.data), {duration: Toast.durations.LONG});
    }
    // console.warn(error.response.status, error.response);

    console.warn('error accured!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
    return Promise.reject(error);
  }
);

export default MyAxios;
