import Vue from "vue";
import Vuetify, {
  VRow,
  VCol,
  VTextField,
  VSimpleCheckbox,
  VCheckbox,
  VSelect,
  VSwitch,
  VFileInput,
  VAutocomplete,
  VTextarea,
  VTooltip,
  VImg,
} from "vuetify/lib";
import { Ripple, Intersect, Touch, Resize } from "vuetify/lib/directives";

Vue.use(Vuetify, {
  components: {
    VRow,
    VCol,
    VTextField,
    VCheckbox,
    VSimpleCheckbox,
    VAutocomplete,
    VSelect,
    VSwitch,
    VFileInput,
    VTextarea,
    VTooltip,
    VImg,
  },
  directives: { Ripple, Intersect, Touch, Resize },
});

export default new Vuetify({
  rtl: true,
});
