<template>
  <footer class="footer-container pa-4 grey lighten-4">
    <v-row justify="center">
      <v-col
        cols="12"
        lg="2"
        md="4"
        sm="6"
        class="text-light ma-md-0 ma-2 text-center"
        v-if="$store.state.settings.footer_links[1]"
      >
        <h4 class="mb-4 orange--text">
          {{ $store.state.settings.footer_links[1][0].title }}
        </h4>
        <div class="d-flex flex-column">
          <router-link
            v-for="(link, i) in $store.state.settings.footer_links[1]"
            :key="'links1' + i"
            :to="fixUrl(link.url)"
            class="text-light mt-2"
          >
            {{ link.name }}
          </router-link>
        </div>
      </v-col>
      <v-col
        cols="12"
        lg="2"
        md="4"
        sm="6"
        class="text-light ma-md-0 ma-2 text-center"
        v-if="$store.state.settings.footer_links[2]"
      >
        <h4 class="mb-4 orange--text">
          {{ $store.state.settings.footer_links[2][0].title }}
        </h4>
        <div class="d-flex flex-column">
          <router-link
            v-for="(link, i) in $store.state.settings.footer_links[2]"
            :key="'links2' + i"
            :to="fixUrl(link.url)"
            class="text-light mt-2"
          >
            {{ link.name }}
          </router-link>
        </div>
      </v-col>
      <v-col
        cols="12"
        lg="2"
        md="4"
        sm="6"
        class="text-light ma-md-0 ma-2 text-center"
        v-if="$store.state.settings.footer_links[3]"
      >
        <h4 class="mb-4 orange--text">
          {{ $store.state.settings.footer_links[3][0].title }}
        </h4>
        <div class="d-flex flex-column">
          <router-link
            v-for="(link, i) in $store.state.settings.footer_links[3]"
            :key="'links3' + i"
            :to="fixUrl(link.url)"
            class="text-light mt-2"
          >
            {{ link.name }}
          </router-link>
        </div>
      </v-col>
      <v-col
        cols="12"
        lg="2"
        md="4"
        sm="6"
        class="text-light ma-md-0 ma-2 text-center"
        v-if="$store.state.settings.footer_links[4]"
      >
        <h4 class="mb-4 orange--text">
          {{ $store.state.settings.footer_links[4][0].title }}
        </h4>
        <div class="d-flex flex-column">
          <router-link
            v-for="(link, i) in $store.state.settings.footer_links[4]"
            :key="'links4' + i"
            :to="fixUrl(link.url)"
            class="text-light mt-2"
          >
            {{ link.name }}
          </router-link>
        </div>
      </v-col>
      <v-col
        cols="12"
        lg="2"
        md="4"
        sm="6"
        class="text-light text-center ma-md-0 ma-2 d-flex"
      >
        <!-- <v-avatar
          size="100"
          tile
          class="ma-auto"
          v-if="$store.state.settings.namad_one"
        >
          <v-img :src="$store.state.settings.namad_one" contain />
        </v-avatar>
        <v-avatar
          size="100"
          tile
          class="ma-auto"
          v-if="$store.state.settings.namad_two"
        >
          <v-img :src="$store.state.settings.namad_two" contain />
        </v-avatar> -->
        <!--        <span-->
        <!--          v-if="$store.state.settings.namad_one"-->
        <!--          v-html="$store.state.settings.namad_one"-->
        <!--        />-->
        <!---->
        <img
          v-if="$store.state.settings.namad_one"
          @click="openEnamad($store.state.settings.namad_one)"
          referrerpolicy="origin"
          src="https://projectapi.kicket.ir/media/enamad.png"
          alt=""
          style="cursor:pointer"
          id="HK6FUtPISTBR1yPDHxhg"
        />
        <img
          v-if="$store.state.settings.namad_two"
          id="nbqeoeukoeukfukzjxlzjxlz"
          style="cursor:pointer"
          @click="openSamandehi($store.state.settings.namad_two)"
          alt="logo-samandehi"
          src="https://logo.samandehi.ir/logo.aspx?id=288611&p=odrfaqgwaqgwwlbqnbpdnbpd"
        />
        <!--        <span-->
        <!--          v-if="$store.state.settings.namad_two"-->
        <!--          v-html="$store.state.settings.namad_two"-->
        <!--        />-->
      </v-col>
    </v-row>
    <v-divider color="white" class="ma-4 mt-8" />
    <v-row class="pa-2">
      <v-col cols="12">
        <div class="text-center orange--text">
          {{ $store.state.settings.copyright }}
        </div>
      </v-col>
      <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown">
        <div class="d-flex mt-4 justify-center">
          <v-tooltip top color="black">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                small
                class="rounded-lg ma-1"
                color="orange"
                dark
                v-if="$store.state.settings.social.instagram"
                @click="navigateTo($store.state.settings.social.instagram)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-instagram</v-icon>
              </v-btn>
            </template>
            <span>{{ "اینستاگرام" }}</span>
          </v-tooltip>
          <v-tooltip top color="black">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                small
                class="rounded-lg ma-1"
                color="blue"
                dark
                v-if="$store.state.settings.social.telegram"
                @click="navigateTo($store.state.settings.social.telegram)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-telegram</v-icon>
              </v-btn>
            </template>
            <span>{{ "تلگرام" }}</span>
          </v-tooltip>
          <v-tooltip top color="black">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                small
                class="rounded-lg ma-1"
                color="red"
                dark
                v-if="$store.state.settings.social.youtube"
                @click="navigateTo($store.state.settings.social.youtube)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-youtube</v-icon>
              </v-btn>
            </template>
            <span>{{ "یوتیوب" }}</span>
          </v-tooltip>
          <v-tooltip top color="black">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                small
                class="rounded-lg ma-1"
                color="pink"
                dark
                v-if="$store.state.settings.social.aparat"
                @click="navigateTo($store.state.settings.social.aparat)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-movie-roll</v-icon>
              </v-btn>
            </template>
            <span>{{ "آپارات" }}</span>
          </v-tooltip>
        </div>
      </v-col>
      <div
        class="socials d-flex mt-4 justify-center"
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <v-tooltip top color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              small
              class="rounded-lg ma-1"
              color="orange"
              dark
              v-if="$store.state.settings.social.instagram"
              @click="navigateTo($store.state.settings.social.instagram)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-instagram</v-icon>
            </v-btn>
          </template>
          <span>{{ "اینستاگرام" }}</span>
        </v-tooltip>
        <v-tooltip top color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              small
              class="rounded-lg ma-1"
              color="blue"
              dark
              v-if="$store.state.settings.social.telegram"
              @click="navigateTo($store.state.settings.social.telegram)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-telegram</v-icon>
            </v-btn>
          </template>
          <span>{{ "تلگرام" }}</span>
        </v-tooltip>
        <v-tooltip top color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              small
              class="rounded-lg ma-1"
              color="red"
              dark
              v-if="$store.state.settings.social.youtube"
              @click="navigateTo($store.state.settings.social.youtube)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-youtube</v-icon>
            </v-btn>
          </template>
          <span>{{ "یوتیوب" }}</span>
        </v-tooltip>
        <v-tooltip top color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              small
              class="rounded-lg ma-1"
              color="pink"
              dark
              v-if="$store.state.settings.social.aparat"
              @click="navigateTo($store.state.settings.social.aparat)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-movie-roll</v-icon>
            </v-btn>
          </template>
          <span>{{ "آپارات" }}</span>
        </v-tooltip>
      </div>
    </v-row>
  </footer>
</template>

<script>
export default {
  methods: {
    navigateTo(url) {
      window.open(url, "_blank");
    },
    openEnamad(url) {
      window.open(url, "_blank");
    },
    openSamandehi(url) {
      window.open(
        url,
        "Popup",
        "toolbar=no, scrollbars=no, location=no, statusbar=no, menubar=no, resizable=0, width=450, height=630, top=30"
      );
    },
    fixUrl(url) {
      return url.replace(/[http | https]+:\/\/moholand.[com | ir]*/g, "");
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
.socials {
  position: absolute;
  left: 2%;
}
</style>
