<template>
  <!--  should be delete  -->
  <div>
    <v-skeleton-loader type="card" class="ma-3 rounded-xl" v-if="loading" />
    <my-swiper
      v-else
      :data="data"
      :maxCount="1"
      fixSize
      hasNavigation
      dark
      class="pa-3 pb-5"
    >
      <template #default="{props}">
        <instant-suggest-item
          :product="props.item"
          :name="props.item.name"
          :price="
            props.item.offer_price ? props.item.offer_price : props.item.price
          "
          :old-price="props.item.offer_price && props.item.price"
          :img="props.item.media ? props.item.media.url : null"
          :url="props.item.url"
        />
        <!-- <v-card :loading="loading" class="mx-auto rounded-xl ma-0" elevation="0">
        <template slot="progress">
          <v-progress-linear
            color="deep-purple"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>

        <v-card-text class="pa-0 text-center pt-4">
          <h3 class="orange--text">پیشنهادات لحظه ای</h3>
          <v-img height="200" :src="img" contain />
          <h3>{{ name || "نام" }}</h3>
          <v-row class="pa-2 text-center">
            <v-col class="green--text">
              {{ new Intl.NumberFormat().format(price) }} تومان
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
              <del>{{ new Intl.NumberFormat().format(oldPrice) }} تومان</del>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card> -->
      </template>
    </my-swiper>
  </div>
</template>

<script>
import MyAxios from "../../constants/MyAxios";
import MySwiper from "../MySwiper.vue";
import InstantSuggestItem from "./InstantSuggestItem.vue";
export default {
  components: { MySwiper, InstantSuggestItem },
  created() {
    this.GetData();
  },
  data() {
    return {
      loading: false,
      data: [],
    };
  },
  methods: {
    GetData() {
      if (!this.loading) this.loading = true;
      MyAxios.get("/suggestions", {
        params: {},
      })
        .then((response) => {
          this.data = response.data?.suggestions;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
