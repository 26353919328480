import Home from "../views/Pages/Home.vue";

export const PanelChildrens = [
  {
    path: "/dashboard",
    name: "DashBoard",
    component: () => import("@/views/Panel/Dashboard"),
    title: "پیشخوان",
    show: true,
    roles: ["vendor", "user", "instructor", "admin"],
  },
  {
    path: "/user/wishes",
    name: "WishList",
    component: () => import("@/views/Panel/WishList.vue"),
    title: "علاقه مندی های من",
    show: true,
    roles: ["user", "vendor", "instructor"],
    meta: {
      title: "علاقه مندی",
      api: "wishlist",
    },
  },
  {
    path: "/user/files/create",
    name: "CreateFile",
    component: () => import("@/views/Panel/MyFiles/create.vue"),
    show: false,
    roles: ["vendor", "instructor"],
  },
  {
    path: "/user/files/edit/:id",
    name: "EditFile",
    component: () => import("@/views/Panel/MyFiles/edit.vue"),
    show: false,
    roles: ["vendor", "instructor"],
  },

  {
    path: "/user/scenarios",
    name: "UserScenarios",
    component: () => import("../views/Panel/MyScenarios/index.vue"),
    title: "داستان ها",
    show: true,
    icon: "mdi-feature-search-outline",
    meta: {
      title: "داستان",
      api: "scenarios",
    },
    roles: ["vendor", "instructor"],
  },
  {
    path: "/user/scenarios/create",
    name: "UserScenariosCreate",
    component: () => import("../views/Panel/MyScenarios/create.vue"),
    icon: "mdi-file-document",
    meta: {
      title: "داستان",
      api: "scenarios",
    },
    roles: ["vendor", "instructor"],
  },
  {
    path: "/user/scenarios/edit/:id",
    name: "UserScenariosEdit",
    component: () => import("../views/Panel/MyScenarios/edit.vue"),
    icon: "mdi-file-document",
    meta: {
      title: "داستان",
      api: "scenarios",
    },
    roles: ["vendor", "instructor"],
  },
  {
    path: "/orders",
    name: "Orders",
    component: () => import("@/views/Panel/Orders.vue"),
    title: "سفارش ها",
    show: true,
    roles: ["vendor", "user", "instructor"],
  },
  {
    path: "/sells",
    name: "SellsList",
    component: () => import("@/views/Panel/SellsList.vue"),
    title: "فروش ها",
    show: true,
    roles: ["vendor", "instructor"],
    // roles: [],
  },
  {
    path: "/messages",
    name: "MyMessages",
    component: () => import("@/views/Panel/MyMessages.vue"),
    title: "پیام های من",
    show: true,
    roles: ["vendor", "instructor"],
  },
  {
    path: "/user/files",
    name: "MyFiles",
    component: () => import("@/views/Panel/MyFiles/index.vue"),
    title: "فایل های من",
    show: true,
    roles: ["vendor", "instructor"],
  },
  {
    path: "/myanimations",
    name: "MyAnimations",
    component: () => import("@/views/Panel/MyAnimations.vue"),
    title: "انیمیشن ها",
    show: true,
    roles: ["vendor", "instructor"],
  },
  {
    path: "/mypodcasts",
    name: "MyPodcasts",
    component: () => import("@/views/Panel/MyPodcasts.vue"),
    title: "گویندگی ها",
    show: true,
    roles: ["vendor", "instructor"],
  },
  {
    path: "/ticket",
    name: "Tickets",
    component: () => import("@/views/Panel/Tickets.vue"),
    title: "تیکت پشتیبانی",
    show: true,
    roles: ["vendor", "user", "instructor"],
  },
  {
    path: "/withdraw",
    name: "Withdraw",
    component: () => import("@/views/Panel/MyWithdraws/index.vue"),
    title: "برداشت پول",
    show: true,
    roles: ["vendor", "instructor"],
  },
  {
    path: "/withdraw/create",
    name: "Withdraw",
    component: () => import("@/views/Panel/MyWithdraws/create.vue"),
    roles: ["vendor", "instructor"],
  },

  {
    path: "/banksVendor",
    name: "BanksVendor",
    title: "بانک های من",
    show: true,
    component: () => import("@/views/Panel/Banks/index.vue"),
    roles: ["vendor", "instructor"],
  },
  {
    path: "/banksVendor/create",
    name: "BanksVendorCreate",
    component: () => import("@/views/Panel/Banks/create.vue"),
    roles: ["vendor", "instructor"],
  },
  {
    path: "/banksVendor/edit/:id",
    name: "BanksVendorEdit",
    component: () => import("@/views/Panel/Banks/edit.vue"),
    roles: ["vendor", "instructor"],
  },
  {
    path: "/mydownloads",
    name: "MyDownloads",
    component: () => import("@/views/Panel/MyDownloads.vue"),
    title: "دانلود های من",
    show: true,
    roles: ["vendor", "user", "instructor"],
  },

  {
    path: "/settings",
    name: "Settings",
    component: () => import("@/views/Panel/Settings"),
    title: "تنظیمات",
    show: true,
    roles: ["vendor", "user", "instructor"],
  },

  {
    path: "/panelsettings",
    name: "PanelSettings",
    component: () => import("@/views/Panel/Admin/Settings/index.vue"),
    title: "تنظیمات قالب",
    show: true,
    roles: ["admin"],
  },
  {
    path: "/menu",
    name: "Menu",
    component: () => import("@/views/Panel/Admin/Menu/index.vue"),
    title: "منو ها",
    show: true,
    roles: ["admin"],
  },
  {
    path: "/menu/create",
    name: "MenuCreate",
    component: () => import("@/views/Panel/Admin/Menu/create.vue"),
    roles: ["admin"],
  },
  {
    path: "/menu/edit/:id",
    name: "MenuEdit",
    component: () => import("@/views/Panel/Admin/Menu/edit.vue"),
    roles: ["admin"],
  },

  {
    path: "/corrupteds",
    name: "Corrupteds",
    title: "گزارشات خرابی فایل",
    show: true,
    component: () => import("@/views/Panel/Admin/Corrupteds/index.vue"),
    roles: ["admin"],
  },

  {
    path: "/reports",
    name: "Reports",
    title: "گزارشات تخلف",
    show: true,
    component: () => import("@/views/Panel/Admin/Reports/index.vue"),
    roles: ["admin"],
  },

  {
    path: "/banks",
    name: "Banks",
    title: "بانک ها",
    show: true,
    component: () => import("@/views/Panel/Admin/Banks/index.vue"),
    roles: ["admin"],
  },
  {
    path: "/banks/create",
    name: "BanksCreate",
    component: () => import("@/views/Panel/Admin/Banks/create.vue"),
    roles: ["admin"],
  },
  {
    path: "/banks/edit/:id",
    name: "BanksEdit",
    component: () => import("@/views/Panel/Admin/Banks/edit.vue"),
    roles: ["admin"],
  },

  {
    path: "/admincategories",
    name: "AdminCategories",
    title: "دسته بندی ها",
    show: true,
    component: () => import("@/views/Panel/Admin/Categories/index.vue"),
    roles: ["admin"],
  },
  {
    path: "/admincategories/create",
    name: "AdminCategoriesCreate",
    component: () => import("@/views/Panel/Admin/Categories/create.vue"),
    roles: ["admin"],
  },
  {
    path: "/admincategories/edit/:id",
    name: "AdminCategoriesEdit",
    component: () => import("@/views/Panel/Admin/Categories/edit.vue"),
    roles: ["admin"],
  },
  {
    path: "/admingenres",
    name: "AdminGenres",
    title: "ژانر داستان ها",
    show: true,
    component: () => import("@/views/Panel/Admin/Genres/index.vue"),
    roles: ["admin"],
  },
  {
    path: "/admingenres/create",
    name: "AdminGenresCreate",
    component: () => import("@/views/Panel/Admin/Genres/create.vue"),
    roles: ["admin"],
  },
  {
    path: "/admingenres/edit/:id",
    name: "AdminGenresEdit",
    component: () => import("@/views/Panel/Admin/Genres/edit.vue"),
    roles: ["admin"],
  },

  {
    path: "/applications",
    name: "Applications",
    title: "نرم افزار ها",
    show: true,
    component: () => import("@/views/Panel/Admin/Applications/index.vue"),
    roles: ["admin"],
  },
  {
    path: "/applications/create",
    name: "ApplicationsCreate",
    component: () => import("@/views/Panel/Admin/Applications/create.vue"),
    roles: ["admin"],
  },
  {
    path: "/applications/edit/:id",
    name: "ApplicationsEdit",
    component: () => import("@/views/Panel/Admin/Applications/edit.vue"),
    roles: ["admin"],
  },

  {
    path: "/comments",
    name: "Comments",
    title: "کامنت ها",
    show: true,
    component: () => import("@/views/Panel/Admin/Comments/index.vue"),
    roles: ["admin"],
  },

  {
    path: "/contactsus",
    name: "ContactUsAdmin",
    title: "ارتباط با ما",
    show: true,
    component: () => import("@/views/Panel/Admin/ContactUsText/index.vue"),
    roles: ["admin"],
  },

  {
    path: "/discounts",
    name: "Discounts",
    title: "تخفیف ها",
    show: true,
    component: () => import("@/views/Panel/Admin/Discounts/index.vue"),
    roles: ["admin"],
  },
  {
    path: "/discounts/create",
    name: "DiscountsCreate",
    component: () => import("@/views/Panel/Admin/Discounts/create.vue"),
    roles: ["admin"],
  },
  {
    path: "/discounts/edit/:id",
    name: "DiscountsEdit",
    component: () => import("@/views/Panel/Admin/Discounts/edit.vue"),
    roles: ["admin"],
  },

  {
    path: "/features",
    name: "Features",
    title: "ویژگی ها",
    show: true,
    component: () => import("@/views/Panel/Admin/Features/index.vue"),
    roles: ["admin"],
  },
  {
    path: "/features/create",
    name: "FeaturesCreate",
    component: () => import("@/views/Panel/Admin/Features/create.vue"),
    roles: ["admin"],
  },
  {
    path: "/features/edit/:id",
    name: "FeaturesEdit",
    component: () => import("@/views/Panel/Admin/Features/edit.vue"),
    roles: ["admin"],
  },
  {
    path: "/coursemanager",
    name: "CoursesManager",
    component: () => import("@/views/Panel/Admin/Courses/index.vue"),
    title: "دوره ها",
    show: true,
    roles: ["admin"],
  },
  {
    path: "/coursemanager/create",
    name: "CoursesCreate",
    component: () => import("@/views/Panel/Admin/Courses/create.vue"),
    roles: ["admin"],
  },
  {
    path: "/coursemanager/edit/:id",
    name: "CoursesEdit",
    component: () => import("@/views/Panel/Admin/Courses/edit.vue"),
    roles: ["admin"],
  },
  {
    path: "/chaptersmanager/index/:course_id?",
    name: "ChaptersManager",
    component: () => import("@/views/Panel/Admin/Chapters/index.vue"),
    title: "سرفصل ها",
    show: true,
    roles: ["admin"],
  },
  {
    path: "/chaptersmanager/create/:course_id?",
    name: "ChaptersCreate",
    component: () => import("@/views/Panel/Admin/Chapters/create.vue"),
    roles: ["admin"],
  },
  {
    path: "/chaptersmanager/edit/:course_id?/:id",
    name: "ChaptersEdit",
    component: () => import("@/views/Panel/Admin/Chapters/edit.vue"),
    roles: ["admin"],
  },
  {
    path: "/ordersmanager",
    name: "OrdersManager",
    title: "سفارش ها",
    show: true,
    component: () => import("@/views/Panel/Admin/Orders/index.vue"),
    roles: ["admin"],
  },
  {
    path: "/ordersmanager/edit/:id",
    name: "OrdersEdit",
    component: () => import("@/views/Panel/Admin/Orders/edit.vue"),
    roles: ["admin"],
  },

  {
    path: "/posts",
    name: "Posts",
    title: "پست ها",
    show: true,
    component: () => import("@/views/Panel/Admin/Posts/index.vue"),
    roles: ["admin"],
  },
  {
    path: "/posts/create",
    name: "PostsCreate",
    component: () => import("@/views/Panel/Admin/Posts/create.vue"),
    roles: ["admin"],
  },
  {
    path: "/posts/edit/:slug",
    name: "PostsEdit",
    component: () => import("@/views/Panel/Admin/Posts/edit.vue"),
    roles: ["admin"],
  },

  {
    path: "/productsmanager",
    name: "ProductsManager",
    title: "فایل ها",
    show: true,
    component: () => import("@/views/Panel/Admin/Products/index.vue"),
    roles: ["admin"],
  },
  {
    path: "/productsmanager/create",
    name: "ProductsManagerCreate",
    component: () => import("@/views/Panel/Admin/Products/create.vue"),
    roles: ["admin"],
  },
  {
    path: "/productsmanager/edit/:id",
    name: "ProductsManagerEdit",
    component: () => import("@/views/Panel/Admin/Products/edit.vue"),
    roles: ["admin"],
  },
  {
    path: "/managescenarios",
    name: "ManageScenarios",
    component: () => import("../views/Panel/Admin/Scenarios/index.vue"),
    title: "داستان ها",
    show: true,
    icon: "mdi-feature-search-outline",
    meta: {
      title: "داستان",
      api: "scenarios",
    },
    roles: ["admin"],
  },

  {
    path: "/managescenarios/create",
    name: "ManageScenariosCreate",
    component: () => import("../views/Panel/Admin/Scenarios/create.vue"),
    icon: "mdi-file-document",
    meta: {
      title: "داستان",
      api: "scenarios",
    },
    roles: ["admin"],
  },
  {
    path: "/managescenarios/edit/:id",
    name: "ManageScenariosEdit",
    component: () => import("../views/Panel/Admin/Scenarios/edit.vue"),
    icon: "mdi-file-document",
    meta: {
      title: "داستان",
      api: "scenarios",
    },
    roles: ["admin"],
  },

  {
    path: "/sellersmanager",
    name: "Sellers",
    title: "فایل های فروشندگان",
    show: true,
    component: () => import("@/views/Panel/Admin/Sellers/index.vue"),
    roles: ["admin"],
  },
  {
    path: "/sellersmanager/create",
    name: "SellersCreate",
    component: () => import("@/views/Panel/Admin/Sellers/create.vue"),
    roles: ["admin"],
  },
  {
    path: "/sellersmanager/edit/:id",
    name: "SellersEdit",
    component: () => import("@/views/Panel/Admin/Sellers/edit.vue"),
    roles: ["admin"],
  },

  {
    path: "/tags",
    name: "Tags",
    title: "تگ ها",
    show: true,
    component: () => import("@/views/Panel/Admin/Tags/index.vue"),
    roles: ["admin"],
  },
  {
    path: "/tags/create",
    name: "TagsCreate",
    component: () => import("@/views/Panel/Admin/Tags/create.vue"),
    roles: ["admin"],
  },
  {
    path: "/tags/edit/:id",
    name: "TagsEdit",
    component: () => import("@/views/Panel/Admin/Tags/edit.vue"),
    roles: ["admin"],
  },

  {
    path: "/tickets",
    name: "TicketsManager",
    title: "تیکت ها",
    show: true,
    component: () => import("@/views/Panel/Admin/Tickets/index.vue"),
    roles: ["admin"],
  },
  {
    path: "/tickets/edit/:id",
    name: "TicketsEdit",
    component: () => import("@/views/Panel/Admin/Tickets/edit.vue"),
    roles: ["admin"],
  },

  {
    path: "/users",
    name: "Users",
    title: "کاربران",
    show: true,
    component: () => import("@/views/Panel/Admin/Users/index.vue"),
    roles: ["admin"],
  },
  {
    path: "/users/create",
    name: "UsersCreate",
    component: () => import("@/views/Panel/Admin/Users/create.vue"),
    roles: ["admin"],
  },
  {
    path: "/users/edit/:id",
    name: "UsersEdit",
    component: () => import("@/views/Panel/Admin/Users/edit.vue"),
    roles: ["admin"],
  },

  {
    path: "/withdraws",
    name: "Withdraws",
    title: "درخواست های برداشت",
    show: true,
    component: () => import("@/views/Panel/Admin/Withdraws/index.vue"),
    roles: ["admin"],
  },
  {
    path: "/withdraws/create",
    name: "WithdrawsCreate",
    component: () => import("@/views/Panel/Admin/Withdraws/create.vue"),
    roles: ["admin"],
  },
  {
    path: "/withdraws/edit/:id",
    name: "WithdrawsEdit",
    component: () => import("@/views/Panel/Admin/Withdraws/edit.vue"),
    roles: ["admin"],
  },

  {
    path: "/useranimations",
    name: "UsersAnimations",
    title: "انیمیشن ها",
    show: true,
    component: () => import("@/views/Panel/Admin/UserAnimations/index.vue"),
    roles: ["admin"],
  },
  {
    path: "/useranimations/create",
    name: "UsersAnimationsCreate",
    component: () => import("@/views/Panel/Admin/UserAnimations/create.vue"),
    roles: ["admin"],
  },
  {
    path: "/useranimations/edit/:id",
    name: "UsersAnimationsEdit",
    component: () => import("@/views/Panel/Admin/UserAnimations/edit.vue"),
    roles: ["admin"],
  },
  {
    path: "/userpodcasts",
    name: "UsersPodcasts",
    title: "گویندگی ها",
    show: true,
    component: () => import("@/views/Panel/Admin/UserPodcasts/index.vue"),
    roles: ["admin"],
  },
  {
    path: "/userpodcasts/create",
    name: "UsersPodcastsCreate",
    component: () => import("@/views/Panel/Admin/UserPodcasts/create.vue"),
    roles: ["admin"],
  },
  {
    path: "/userpodcasts/edit/:id",
    name: "UsersPodcastsEdit",
    component: () => import("@/views/Panel/Admin/UserPodcasts/edit.vue"),
    roles: ["admin"],
  },
  {
    path: "/managepages",
    name: "Pages",
    component: () => import("../views/Panel/Admin/Pages/index.vue"),
    title: "برگه ها",
    show: true,
    icon: "mdi-feature-search-outline",
    meta: {
      title: "برگه",
      api: "pages",
    },
    roles: ["admin"],
  },
  {
    path: "/managepages/create",
    name: "PagesCreate",
    component: () => import("../views/Panel/Admin/Pages/create.vue"),
    icon: "mdi-file-document",
    meta: {
      title: "برگه",
      api: "pages",
    },
    roles: ["admin"],
  },
  {
    path: "/managepages/edit/:id",
    name: "PagesEdit",
    component: () => import("../views/Panel/Admin/Pages/edit.vue"),
    icon: "mdi-file-document",
    meta: {
      title: "برگه",
      api: "pages",
    },
    roles: ["admin"],
  },
];

export default [
  {
    path: "/",
    name: "Home",
    component: Home,
    title: "صفحه اصلی",
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login/Login.vue"),
    title: "ورود",
  },
  {
    path: "/register/",
    name: "Register",
    component: () => import("../views/Register/Register.vue"),
    title: "ثبت نام",
    props: true,
  },
  {
    path: "/page/:slug",
    name: "Page",
    component: () => import("../views/Pages/Page.vue"),
    title: "برگه",
    props: true,
  },
  {
    path: "/singlepage",
    name: "SinglePage",
    component: () => import("../views/Pages/SinglePage.vue"),
  },
  {
    path: "/payment/complete/:payment",
    name: "Payment",
    component: () => import("../views/Payments/Payment.vue"),
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: () => import("../views/Checkout/Checkout.vue"),
  },
  {
    path: "/products",
    name: "Products",
    component: () => import("../views/Pages/Products.vue"),
  },
  {
    path: "/products/:slug",
    name: "Product",
    component: () => import("../views/Pages/Product.vue"),
  },
  {
    path: "/podcasts",
    name: "Podcasts",
    component: () => import("../views/Pages/Podcasts.vue"),
  },
  {
    path: "/animations",
    name: "Animations",
    component: () => import("../views/Pages/Animations.vue"),
  },
  {
    path: "/scenarios",
    name: "Scenarios",
    component: () => import("../views/Pages/Scenarios.vue"),
  },
  {
    path: "/scenarios/:slug",
    name: "Scenario",
    component: () => import("../views/Pages/Scenario.vue"),
  },
  {
    path: "/courses",
    name: "Courses",
    component: () => import("../views/Pages/Courses.vue"),
  },
  {
    path: "/courses/:slug",
    name: "Course",
    component: () => import("../views/Pages/Course.vue"),
  },
  {
    path: "/sellers",
    name: "SellersList",
    component: () => import("../views/Pages/SellersList.vue"),
  },
  {
    path: "/sellers/:id",
    name: "SellerPage",
    component: () => import("../views/Pages/SellerPage.vue"),
  },
  {
    path: "/categories",
    name: "Categories",
    component: () => import("../views/Pages/Categories.vue"),
  },
  {
    path: "/contactus",
    name: "ContactUs",
    component: () => import("../views/Pages/ContactUs.vue"),
  },
  {
    path: "/aboutus",
    name: "AboutUs",
    component: () => import("../views/Pages/AboutUs.vue"),
  },
  {
    path: "/articles",
    name: "Articles",
    component: () => import("../views/Pages/Articles.vue"),
  },
  {
    path: "/articles/:slug",
    name: "Article",
    component: () => import("../views/Pages/Article.vue"),
  },
  {
    path: "*",
    name: "notfound",
    redirect: { name: "404" },
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/404/404.vue"),
  },
  {
    path: "/panel",
    component: () => import("../views/Panel"),
    title: "پنل کاربری",
    children: PanelChildrens,
  },
];
