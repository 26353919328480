import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueCountdown from "@chenfengyuan/vue-countdown";
import OtpInput from "@bachdgvn/vue-otp-input";
import "@/assets/css/main.css";

Vue.config.productionTip = false;

Vue.component("v-otp-input", OtpInput);
Vue.component("ModalTimePicker", () => import("./components/ModalTimePicker"));
Vue.component("ModalDatePicker", () => import("./components/ModalDatePicker"));
Vue.component("MyAvatarPicker", () => import("./components/MyAvatarPicker"));
Vue.component("DialogEditPassword", () =>
  import("./components/DialogEditPassword")
);
Vue.component("DialogClientReport", () =>
  import("./components/DialogClientReport")
);
Vue.component("DialogAboutUs", () => import("./components/DialogAboutUs"));
Vue.component("MyFilePond", () => import("./components/MyFilePond"));
Vue.component("MyFilePond", () => import("./components/MyFilePond"));
Vue.component("MyCKEditor", () => import("./components/MyCKEditor"));
Vue.component("MyTagSelector", () =>
  import("./components/Panel/MyTagSelector")
);
Vue.component("MyTinyMCE", () => import("./components/MyTinyMCE"));
Vue.component("text-price-field", () =>
  import("./components/text-price-field")
);
Vue.component("VueCountdown", VueCountdown);

Vue.component("CustomInput", () => import("./components/CustomInput"));
Vue.component("VFormTitle", () => import("./components/VFormTitle"));

require("moment/locale/fa");
Vue.use(require("vue-moment-jalaali"));

// Vue.config.ignoredElements = ["VFormBase"];

Vue.config.silent = true;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
