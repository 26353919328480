<template>
  <div class="main-div rounded-lg">
    <transition name="fadeHeight" mode="out-in">
      <v-card v-if="show == false">
        <v-badge
          v-if="$store.state.cart.products.length > 0"
          color="red"
          :content="$store.state.cart.products.length"
          overlap
          left
          offset-x="15"
          offset-y="15"
        >
          <v-btn
            fab
            color="white"
            elevation="5"
            class="my-fab rounded-lg"
            @click="onFabClicked"
          >
            <v-icon size="40">
              mdi-basket-outline
            </v-icon>
          </v-btn>
        </v-badge>
        <v-btn
          v-else
          fab
          color="white"
          elevation="5"
          class="my-fab rounded-lg"
          @click="onFabClicked"
        >
          <v-icon size="40">
            mdi-basket-outline
          </v-icon>
        </v-btn>
      </v-card>
      <div class="text-right pa-3 elevation-5" v-else>
        <h2 class="orange--text">سبد خرید</h2>
        <div class="cart-items pa-1">
          <div v-for="(product, i) in $store.state.cart.products" :key="i">
            <product-list-box
              v-if="product.product"
              :productId="product.id"
              :img="
                product.product &&
                product.product.media &&
                product.product.media.url
                  ? product.product.media.url
                  : require('@/assets/img/man.jpg')
              "
              :title="product.product.name"
              :price="product.price"
            />
            <product-list-box
              v-else-if="product.course"
              :productId="product.id"
              :img="
                product.course &&
                product.course.media &&
                product.course.media.url
                  ? product.course.media.url
                  : null
              "
              :title="product.course.title"
              :price="product.price"
            />
            <product-list-box
              v-else-if="product.scenario"
              :productId="product.id"
              :title="product.scenario.title"
              :price="product.price"
            />
          </div>
        </div>
        <div class="d-flex mt-4 justify-space-between">
          <v-btn
            color="grey"
            elevation="0"
            x-large
            class="rounded-lg my-auto lighten-1"
            @click="onFabClicked"
          >
            <v-icon class="fill-height">
              mdi-close
            </v-icon>
          </v-btn>
          <v-btn
            color="green lighten-1 w-80"
            elevation="0"
            style="flex: 1"
            class="my-fab rounded-lg mr-2"
            @click="onBuyClick"
            dark
            x-large
          >
            خرید
          </v-btn>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ProductListBox from "./ProductListBox.vue";
import MyAxios from "@/constants/MyAxios";
import { getUnixDifferenceTimeFromNow } from "@/constants/TimeFunctions";
import moment from "moment";

export default {
  components: { ProductListBox },
  data() {
    return {
      show: false,
      loading: false,
    };
  },
  mounted() {
    this.getCart();
    this.$root.$on("addToCart", (item, callback = null, type = "product") => {
      this.addToCart(item, callback, type);
    });
    this.$root.$on("removeFromCart", (item, callback = null) => {
      this.removeFromCart(item, callback);
    });
    this.$root.$on("getCart", (callback = null) => {
      this.getCart(callback);
    });
    this.$root.$on("removeCart", (callback = null) => {
      this.removeCart(callback);
    });
    this.$root.$on("assignCartToUser", (user_id, callback = null) => {
      this.assignCartToUser(user_id, callback);
    });
  },
  beforeDestroy() {
    this.$root.$off("addToCart");
    this.$root.$off("removeFromCart");
    this.$root.$off("getCart");
    this.$root.$off("removeCart");
    this.$root.$off("assignCartToUser");
  },
  methods: {
    onFabClicked() {
      this.show = !this.show;
    },
    getCart(callback) {
      if (!this.$store.state.userData && !this.$store.state.storage_id) {
        this.$store.commit("resetCart");
        return;
      }

      if (!this.loading) this.loading = true;

      if (!!callback) callback(true);

      let conditions = {};
      if (this.$store.state.userData?.id) {
        conditions = {
          user_id: this.$store.state.userData?.id,
        };
      } else if (this.$store.state.storage_id) {
        conditions = {
          storage_id: this.$store.state.storage_id,
        };
      }

      if (Object.keys(conditions).length > 0)
        MyAxios.get("carts/index", {
          params: {
            with: ["products", "products.vendor"],
            noPaginate: true,
            conditions,
            latest: true,
          },
        })
          .then(({ data }) => {
            if (data.deleted_at) {
              this.$store.commit("resetCart");
            } else {
              const cart = {
                id: data.id || null,
                products: data.products || [],
                total_price: data.total_price || 0,
              };
              this.$store.commit("setCart", cart);
            }
            this.loading = false;
            if (!!callback) callback(false);
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
            if (
              error?.response?.data?.message
                ?.toLowerCase()
                ?.includes("No Query")
            )
              this.removeCart();
            if (!!callback) callback(false);
          });
    },
    addToCart(item, callback, type = "product") {
      if (this.loading) {
        this.$root.$emit("toast", {
          text: "فایل قبلی در حال اضافه شدن است. لطفا منتظر بمانید.",
        });
        if (callback) callback("error");
        return;
      }

      if (item.is_bought) {
        this.$root.$emit("toast", {
          text: "شما قبلا این فایل را خریداری کرده اید.",
        });
        if (callback) callback("error");
        return;
      }

      let storage_id = null;
      if (!this.$store.state.userData) {
        if (this.$store.state.storage_id) {
          storage_id = this.$store.state.storage_id;
        } else {
          storage_id =
            "u" +
            Math.random()
              .toFixed(10)
              .toString()
              .slice(2);
          this.$store.commit("setStorageId", storage_id);
        }
      }

      if (
        this.$store.state.cart?.products &&
        this.$store.state.cart?.products.findIndex((val) =>
          type == "product"
            ? val.product && val.product?.id == item.id
            : type == "scenario"
            ? val.scenario && val.scenario?.id == item.id
            : val.course && val.course?.id == item.id
        ) >= 0
      ) {
        this.$root.$emit("toast", {
          text:
            type == "product"
              ? "این فایل در سبد خرید شما وجود دارد."
              : type == "scenario"
              ? "این داستان در سبد خرید شما وجود دارد."
              : "این دوره در سبد خرید شما وجود دارد.",
        });
        if (callback) callback("error");
        return;
      }

      if (!this.loading) this.loading = true;

      let products = this.$store.state.cart?.products
        .map((product) => ({
          product_id: product.product_id ? product.product_id : undefined,
          course_id: product.course_id ? product.course_id : undefined,
          scenario_id: product.scenario_id ? product.scenario_id : undefined,
          price: product.price,
        }))
        .filter((val) => val.product_id || val.course_id);

      let productEndOfferUnixTime = getUnixDifferenceTimeFromNow(
        item.end_date_offer
      );

      products.push({
        product_id: type == "product" ? item.id : undefined,
        course_id: type == "course" ? item.id : undefined,
        scenario_id: type == "scenario" ? item.id : undefined,
        price:
          productEndOfferUnixTime && productEndOfferUnixTime < 0
            ? item.offer_price || item.price
            : item.price,
      });

      MyAxios.post(
        "carts/create" +
          (this.$store.state.cart?.id ? "/" + this.$store.state.cart?.id : ""),
        {
          products,
          storage_id: storage_id || undefined,
          user_id: this.$store.state.userData?.id || undefined,
        }
      )
        .then((response) => {
          this.loading = false;

          this.$store.commit("setCart", {
            id: response.data?.cart?.id || null,
            products: response.data?.products || [],
            total_price: response.data?.cart?.total_price || 0,
          });
          this.$root.$emit("toast", {
            text: "فایل به سبد خرید افزوده شد",
            type: "success",
          });

          if (callback) callback("success");
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("toast", {
            text: "مشکلی پیش آمده است. لطفا دوباره سعی کنید",
          });
          console.log(error);
          if (
            error?.response?.data?.message?.toLowerCase()?.includes("no query")
          )
            this.removeCart();

          if (callback) callback("error");
        });
    },
    removeFromCart(id, callback) {
      if (this.loading) {
        this.$root.$emit("toast", {
          text: "فایل قبلی در حال حذف شدن است. لطفا منتظر بمانید.",
        });
        if (callback) callback("error");
        return;
      }

      if (!this.loading) this.loading = true;

      MyAxios.delete("carts/products/" + id + "/delete")
        .then((response) => {
          this.loading = false;

          this.$store.commit("setCart", {
            id: response.data?.cart?.id || null,
            products: response.data?.products || [],
            total_price: response.data?.cart?.total_price || 0,
          });

          this.$root.$emit("toast", {
            text: "فایل از سبد خرید حذف شد",
            type: "success",
          });

          if (callback) callback("success");
        })
        .catch((error) => {
          this.loading = false;

          this.removeCart();

          this.$root.$emit("toast", {
            text: "مشکلی پیش آمده است. لطفا دوباره سعی کنید",
          });
          console.log(error);

          if (callback) callback("error");
        });
    },
    removeCart(callback) {
      if (!this.$store.state.cart?.id) {
        this.$store.commit("resetCart");
        return;
      }
      if (!this.loading) this.loading = true;

      let cartId = this.$store.state.cart?.id;

      this.$store.commit("resetCart");

      MyAxios.delete("carts/" + cartId + "/delete")
        .then((response) => {
          this.loading = false;

          if (callback) callback("success");
        })
        .catch((error) => {
          this.loading = false;

          if (callback) callback("error");
        });
    },
    assignCartToUser(user_id, callback) {
      if (!this.$store.state.cart?.id) {
        callback && callback("success");
        return;
      }
      if (!this.loading) this.loading = true;

      let cartId = this.$store.state.cart?.id;

      MyAxios.post("carts/" + cartId + "/update", {
        user_id,
      })
        .then((response) => {
          this.loading = false;

          if (callback) callback("success");
        })
        .catch((error) => {
          this.loading = false;

          if (callback) callback("error");
        });
    },
    onBuyClick() {
      if (this.$store.state.userData) this.$router.push("/checkout");
      else {
        this.$root.$emit("toast", {
          text:
            "برای خرید از سایت لطفا وارد پنل کاربری خود شوید و یا اگر عضو سایت نیستید ثبت نام نمایید.",
          type: "info",
        });
        this.$router.push("/login?checkout=true");
      }
    },
  },
};
</script>

<style scoped>
.main-div {
  position: fixed;
  right: 2%;
  bottom: 2%;
  z-index: 10;
  background-color: white;
}
.my-fab {
  width: 6em;
  height: 6em;
}

.cart-items {
  position: relative;
  overflow: auto;
  max-height: 50vh;
}
</style>
