<template>
  <v-container class="container d-flex justify-center pa-0" fluid>
    <MySnackbar v-model="alert.show" :type="alert.type" :text="alert.text" />
    <v-main class="content left-bg white--text">
      <!--     اسلایدر های صفحه اصلی    -->
      <v-row style="background: #c3c3c3" class="pt-3" no-gutters>
        <v-container>
          <v-row>
            <v-col md="8" cols="12">
              <my-swiper
                :data="$store.state.settings.sliders"
                :maxCount="1"
                fixSize
                hasPaginate
                hasNavigation
                class="pa-3 pb-2"
              >
                <template #default="{props}">
                  <v-img
                    class="rounded-xl moholand-shadow cursor-pointer"
                    :src="props.item.media.url"
                    @click="navigateTo(props.item.url)"
                    height="435px"
                  >
                  </v-img>
                </template>
              </my-swiper>
            </v-col>
            <v-col sm="7" md="4" cols="12" class="mx-auto">
              <my-swiper
                :data="suggestions"
                :maxCount="1"
                fixSize
                dark
                class="pa-3 pb-5"
              >
                <template #default="{props}">
                  <instant-suggest-item
                    :product="props.item"
                    :name="props.item.name"
                    :price="
                      props.item.offer_price
                        ? props.item.offer_price
                        : props.item.price
                    "
                    :old-price="props.item.offer_price && props.item.price"
                    :img="props.item.media ? props.item.media.url : null"
                    :url="props.item.url"
                  />
                </template>
              </my-swiper>
            </v-col>
          </v-row>
        </v-container>
      </v-row>

      <!--     پیشنهاد های ویژه    -->
      <v-row style="background: #F54337" v-if="offers.length" no-gutters>
        <v-container>
          <v-row class="mt-2">
            <v-col md="2" cols="12" align-self="center" class="text-center">
              <div class="similar-title text-center white--text">
                پیشنهادات
                <br />
                شگفت انگیز
              </div>
              <v-btn
                large
                outlined
                color="white"
                class="mt-3"
                :to="$store.state.settings.offers_url || undefined"
              >
                مشاهده همه
              </v-btn>
            </v-col>
            <v-col md="10" cols="12">
              <my-swiper
                :data="offers"
                :maxCount="4"
                class="amazing-offer-swiper"
                hasNavigation
                dark
                fixSpace
                :loop="false"
                :spaceBetween="10"
              >
                <template #default="{props}">
                  <amazing-offer-item
                    :product="props.item"
                    :name="props.item.name"
                    :price="
                      props.item.offer_price
                        ? props.item.offer_price
                        : props.item.price
                    "
                    :old-price="props.item.offer_price && props.item.price"
                    :img="props.item.media ? props.item.media.url : null"
                    :date="props.item.offer_end_date"
                    :url="props.item.url"
                  />
                </template>
              </my-swiper>
            </v-col>
          </v-row>
        </v-container>
      </v-row>

      <!--  فایل های  -->
      <v-row style="background: #EFEFEF" no-gutters>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-tabs dark background-color="#EFEFEF" show-arrows>
                <v-tabs-slider color="transparent"></v-tabs-slider>
                <v-tab class="pa-0 ma-0">
                  <v-btn
                    class="ma-2"
                    elevation="0"
                    rounded
                    :style="selectedCategory !== null && 'color: #808080'"
                    :color="selectedCategory === null ? '#FB6D00' : '#D0D0D0'"
                    :dark="selectedCategory === null"
                    @click="selectCategory(null)"
                  >
                    جدید ترین ها
                  </v-btn>
                </v-tab>
                <v-tab
                  class="pa-0 ma-0"
                  v-for="item in categories"
                  :key="item.id"
                  :href="'#tab-' + item.id"
                >
                  <v-btn
                    @click="selectCategory(item.id)"
                    class="ma-2"
                    :style="selectedCategory !== item.id && 'color: #808080'"
                    elevation="0"
                    rounded
                    :dark="selectedCategory === item.id"
                    :color="
                      selectedCategory === item.id ? '#FB6D00' : '#D0D0D0'
                    "
                  >
                    {{ item.name }}
                  </v-btn>
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
          <v-row v-if="products_loading">
            <v-col
              lg="3"
              md="4"
              sm="6"
              cols="12"
              v-for="(item, i) in new Array(10)"
              :key="i"
            >
              <v-skeleton-loader
                v-bind="attrs"
                type="image,list-item-two-line, table-tfoot"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row v-if="!products_loading">
            <v-col
              lg="3"
              md="4"
              sm="6"
              cols="12"
              v-for="(product, i) in products"
              :key="'products' + i"
            >
              <ProductBox
                :product="product"
                :name="product.name"
                :url="product.url"
                :img="product.media ? product.media.url : null"
                :price="
                  product.offer_price ? product.offer_price : product.price
                "
                :old-price="product.offer_price && product.price"
              />
            </v-col>
          </v-row>
          <v-row
            align="center"
            justify="center"
            class="my-15"
            v-if="!products_loading && !products.length"
          >
            <h3 class="text-center grey--text">هیچ فایلی وجود ندارد</h3>
          </v-row>
          <v-row
            align="center"
            justify="center"
            class="mb-3"
            v-if="!products_loading && page < last_page"
          >
            <v-btn elevation="0" @click="GetMore">
              فایل های بیشتر
            </v-btn>
          </v-row>
        </v-container>
      </v-row>

      <!--  فروشنده برتر ، محبوب ترین فایل ها  -->
      <v-row no-gutters>
        <v-container>
          <v-row>
            <v-col md="6" cols="12">
              <v-card color="info" dark class="fill-height rounded-lg">
                <v-card-text class="pa-0 d-flex align-center fill-height">
                  <v-row
                    justify="space-between"
                    align="center"
                    class="my-auto"
                    v-if="bestseller"
                  >
                    <v-col cols="12" md="3" class="pl-0 ml-0">
                      <v-img
                        :src="
                          bestseller.avatar_url ||
                            require('../../assets/img/man.jpg')
                        "
                        class="product-img rounded-l-xl pl-0 ml-0"
                      />
                    </v-col>
                    <v-col cols="12" md="5" class="text-right">
                      <p class="white--text">
                        فروشنده برتر
                      </p>
                      <h2 class="white--text">
                        {{ bestseller.first_name }} {{ bestseller.last_name }}
                      </h2>
                    </v-col>
                    <v-col cols="12" md="4" class="text-center">
                      <v-btn
                        block
                        elevation="0"
                        large
                        color="orange darken-2"
                        class="rounded-r-lg rounded-l-0"
                        :to="'/sellers/' + bestseller.id"
                      >
                        مشاهده پروفایل
                      </v-btn>
                      <!-- <v-rating
                        color="orange"
                        dark
                        size="24"
                        class="vendor-rating mt-1"
                        :value="4"
                        readonly
                      /> -->
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="6" cols="12">
              <v-card
                color="orange darken-2"
                dark
                class="fill-height rounded-lg py-8"
              >
                <v-card-title class="pa-0">
                  <v-row justify="space-between" align="center">
                    <v-col cols="12" md="3" class="text-center">
                      <h3>محبوب ترین</h3>
                      <h3>فایل ها!</h3>
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                      class="text-center"
                      v-for="(item, i) in bestProducts"
                      :key="'bestProducts' + i"
                      @click="$router.push({ path: item.url })"
                      style="cursor: pointer"
                    >
                      <div
                        class="d-flex flex-column text-center"
                        style="max-width: 90px"
                      >
                        <v-chip
                          class="rounded-xl justify-center"
                          text-color="white"
                          label
                          color="info"
                        >
                          {{ item.download_count }}
                          دانلود
                        </v-chip>
                        <v-badge
                          :color="
                            i == 0
                              ? 'yellow darken-1'
                              : i == 1
                              ? 'blue darken-1'
                              : 'red'
                          "
                          :content="i + 1"
                          bottom
                          left
                          overlap
                          offset-x="15"
                          offset-y="15"
                        >
                          <v-img
                            :src="item.media ? item.media.url : null"
                            class="product-img rounded-xl mt-2 mx-auto elevation-4"
                          >
                          </v-img>
                        </v-badge>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-row>

      <!--  ویژگی ها  -->
      <v-row no-gutters>
        <v-container>
          <v-row class="text-center">
            <v-col
              sm="6"
              md="3"
              cols="12"
              v-for="(icon, i) in $store.state.settings.icons_media"
              :key="'icons' + i"
            >
              <v-card class="moholand-shadow rounded-lg">
                <v-card-text class="d-flex justify-center align-center">
                  <v-img
                    v-if="icon.media"
                    :src="icon.media.url"
                    max-height="80"
                    max-width="80"
                  />
                  <h2
                    class="orange--text text-center mr-2"
                    style="line-height: 1.5em"
                    v-html="icon.title"
                  ></h2>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- <v-col sm="6" md="3" cols="12">
              <v-card class="moholand-shadow rounded-lg">
                <v-card-text class="d-flex justify-center align-center">
                  <v-icon
                    size="70"
                    color="orange darken-2"
                    class="text-right ml-2"
                  >
                    mdi-update
                  </v-icon>
                  <h2
                    class="orange--text text-justify"
                    style="line-height: 1.5em"
                  >
                    بــروز رسانی
                    <br />
                    مادام العمر
                  </h2>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col sm="6" md="3" cols="12">
              <v-card class="moholand-shadow rounded-lg">
                <v-card-text class="d-flex justify-center align-center">
                  <v-icon
                    size="70"
                    color="orange darken-2"
                    class="text-right ml-2"
                  >
                    mdi-credit-card-outline
                  </v-icon>
                  <h2
                    class="orange--text text-justify"
                    style="line-height: 1.5em"
                  >
                    خرید آسان
                    <br />
                    آنــــــــــــــــلاین
                  </h2>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col sm="6" md="3" cols="12">
              <v-card class="moholand-shadow rounded-lg">
                <v-card-text class="d-flex justify-center align-center">
                  <v-icon
                    size="70"
                    color="orange darken-2"
                    class="text-right ml-2"
                  >
                    mdi-shield-check-outline
                  </v-icon>
                  <h2
                    class="orange--text text-justify"
                    style="line-height: 1.5em"
                  >
                    تضمین
                    <br />
                    کـیفیـت
                  </h2>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col sm="6" md="3" cols="12">
              <v-card class="moholand-shadow rounded-lg">
                <v-card-text class="d-flex justify-center align-center">
                  <v-icon
                    size="70"
                    color="orange darken-2"
                    class="text-right ml-2"
                  >
                    mdi-face-agent
                  </v-icon>
                  <h2
                    class="orange--text text-justify"
                    style="line-height: 1.5em"
                  >
                    پشتیبانی
                    <br />
                    فــایل ها
                  </h2>
                </v-card-text>
              </v-card>
            </v-col> -->
          </v-row>
        </v-container>
      </v-row>

      <!--  ویژگی ها  -->
      <v-row class="mb-4" no-gutters>
        <v-container class="black--text">
          <v-row class="info--text text-center">
            <v-col sm="6" md="3" cols="12">
              <div>
                <span class="text-large font-weight-bold">
                  {{ numbers.products_count }}
                </span>
                فایل آماده دانلود
              </div>
            </v-col>
            <v-col sm="6" md="3" cols="12">
              <div>
                <span class="text-large font-weight-bold">
                  {{ numbers.users_count }}</span
                >
                موهولندی
              </div>
            </v-col>
            <v-col sm="6" md="3" cols="12">
              <div>
                <span class="text-large font-weight-bold">
                  {{ numbers.sellers_count }}</span
                >
                فروشنده
              </div>
            </v-col>
            <v-col sm="6" md="3" cols="12">
              <div>
                <span class="text-large font-weight-bold">
                  {{ numbers.questions_count }}</span
                >
                سوال و جواب
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
    </v-main>
  </v-container>
</template>

<script>
import { Constants } from "../../constants/GlobalConstants";

import Axios from "axios";
import MyAxios from "../../constants/MyAxios";

import MySnackbar from "@/components/MySnackbar";
import Header from "@/components/Pages/Header";
import MySwiper from "@/components/MySwiper";
import ProductBox from "../../components/Pages/ProductBox";
import SearchModal from "../../components/Pages/SearchModal.vue";
import MomentsOffer from "../../components/Pages/MomentsOffer.vue";
import AmazingOfferItem from "../../components/Pages/AmazingOfferItem.vue";
import Footer from "../../components/Pages/Footer.vue";
import InstantSuggestItem from "../../components/Pages/InstantSuggestItem";

export default {
  name: "Home",
  data() {
    return {
      isTokenAvailable: false,
      products_loading: true,
      offer_loading: true,
      alert: {
        show: false,
        text: "",
        type: "error",
      },
      offers: [],
      suggestions: [],
      products: [],
      page: 1,
      last_page: 1,
      menus: [],
      categories: [],
      selectedCategory: null,
      bestseller: [],
      bestProducts: [],
      numbers: {
        products_count: 0,
        questions_count: 0,
        sellers_count: 0,
        users_count: 0,
      },
    };
  },
  components: {
    InstantSuggestItem,
    ProductBox,
    MySnackbar,
    Header,
    MySwiper,
    SearchModal,
    MomentsOffer,
    AmazingOfferItem,
    Footer,
  },
  mounted() {
    this.GetData();
    // this.GetBestSellerAndProducts();
  },
  methods: {
    selectCategory: function(id) {
      this.selectedCategory = id;
      this.products = [];
      this.page = 1;
      this.last_page = 1;
      this.GetProducts();
    },
    GetMore() {
      // this.page += 1;
      // this.GetProducts();
      this.$router.push("/products");
    },
    GetData: function() {
      this.$root.$emit("globalLoadingSet", true);
      this.products_loading = true;
      this.offer_loading = true;
      MyAxios.get("/pages/home", {
        params: {
          // conditions: {
          //   parent_id: null,
          // },
        },
      })
        .then((response) => {
          this.offers = response.data.offers;
          this.suggestions = response.data.suggestions;
          this.products = response.data.products.data;
          this.page = response.data.products.current_page;
          this.last_page = response.data.products.last_page;
          this.bestProducts = response.data.bestProducts
            ? Object.values(response.data.bestProducts)
            : [];
          this.numbers = response.data.numbers;
          this.bestseller = response.data.settings?.best_seller;
          // this.menus = response.data.menus;
          this.categories = response.data.categories
            ? response.data.categories.filter((val) => val.parent_id == 7)
            : [];
          this.products_loading = false;
          this.offer_loading = false;
          this.$store.commit("setSettings", response.data.settings || {});
          this.$root.$emit("globalLoadingSet", false);
        })
        .catch((error) => {
          console.log(error);
          this.products_loading = false;
          this.offer_loading = false;
          this.$root.$emit("globalLoadingSet", false);
        });
    },
    GetProducts: function() {
      this.products_loading = true;
      MyAxios.get(
        "/products/filter" +
          (this.selectedCategory ? "/" + this.selectedCategory : ""),
        {
          params: {
            // conditions: {
            //   category_id: this.selectedCategory,
            // },
            page: this.page,
          },
        }
      )
        .then((response) => {
          this.products_loading = false;
          this.last_page = response.data.last_page;
          this.products = this.products.concat(response.data.data);
        })
        .catch((error) => {
          this.products_loading = false;
          console.log(error);
        });
    },
    // GetBestSellerAndProducts: function() {
    //   MyAxios.get("/sellers/bestseller/products", {})
    //     .then((response) => {
    //       console.log(response.data);
    //       this.bestseller = response.data[0];
    //       this.bestProducts = response.data[1];
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    navigateTo(url) {
      if (url) window.open(url, "_blank");
    },
    showAlert(txt) {
      this.alertText = txt;
      this.alert = true;
    },
  },
};
</script>

<style scoped>
.similar-title {
  font-size: 2em;
}
.product-img {
  /* width: 20em;
    height: 20em; */
  max-height: 90px;
  max-width: 90px;
}
</style>
