<template>
  <v-menu open-on-hover bottom offset-y v-if="$store.state.userData">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-badge
          v-if="$store.state.userNotifications.length"
          color="error"
          :content="$store.state.userNotifications.length"
          left
          overlap
        >
          <v-icon>mdi-email-outline</v-icon>
        </v-badge>
        <v-icon v-else>mdi-email-outline</v-icon>
      </v-btn>
    </template>

    <v-list
      max-width="400px"
      max-height="70vh"
      v-if="$store.state.userNotifications.length"
    >
      <div class="d-flex align-center">
        <small>نوتیفیکیشن ها</small>
        <v-spacer />

        <v-tooltip bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              x-small
              fab
              icon
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="setNotificationAsRead()"
            >
              <v-icon>mdi-checkbox-marked-outline</v-icon>
            </v-btn>
          </template>
          <small>علامت به عنوان خوانده شده</small>
        </v-tooltip>
      </div>
      <div
        v-for="(notif, i) in $store.state.userNotifications.filter(
          (v) => !!v.data
        )"
        :key="'notif' + i"
        class="px-2 white"
        :class="{ 'grey lighten-3 grey--text': notif.read_at }"
      >
        <div class="d-flex">
          <small>{{ notif.data.message }}</small>
          <!-- <v-tooltip bottom color="black" v-if="!notif.read_at">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                fab
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="setNotificationAsRead(notif)"
              >
                <v-icon>mdi-checkbox-marked-outline</v-icon>
              </v-btn>
            </template>
            <small>علامت به عنوان خوانده شده</small>
          </v-tooltip> -->
        </div>
        <v-divider />
      </div>
    </v-list>
    <v-list width="300px" v-else class="px-2">
      <div>پیامی ندارید.</div>
    </v-list>
  </v-menu>
</template>

<script>
import MyAxios from "../../constants/MyAxios";
export default {
  created() {
    if (this.$store.state.userData) this.getNotifications();
  },
  data() {
    return {
      loading: false,
    };
  },
  watch: {
    "$route.path"() {
      if (this.$store.state.userData) {
        this.getNotifications();
        this.getBalance();
      }
    },
  },
  methods: {
    getNotifications() {
      if (!this.loading) this.loading = true;

      MyAxios.post("/user/notifications")
        .then((response) => {
          this.$store.commit(
            "setUserNotification",
            response.data?.data?.notifications || []
          );
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    getBalance() {
      if (!this.loading) this.loading = true;

      MyAxios.get("/users/balance", {
        params: {},
      })
        .then((response) => {
          this.loading = false;
          this.$store.commit("balanceAndNotificationsCount", response.data);
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    setNotificationAsRead(notification) {
      //   MyAxios.post("/notifications/read", {
      //     id: notification.id,
      //   })
      //     .then((response) => {
      //       //   this.$store.commit(
      //       //     "setUserNotification",
      //       //     response.data?.data?.notifications || []
      //       //   );
      //     })
      //     .catch((error) => {});
      MyAxios.get("/notifications/read")
        .then((response) => {
          this.$store.commit(
            "setUserNotification",
            this.$store.state.userNotifications.map((notif) => ({
              ...notif,
              read_at: true,
            })) || []
          );
        })
        .catch((error) => {});
    },
  },
};
</script>

<style></style>
